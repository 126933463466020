const Config = {
	// defaultPath: '/dashboard/default',
	// basename: '/able-pro/react/default',
	defaultPath: '/signin',
	basename: '',
	layout: 'vertical',
	subLayout: '',
	collapseMenu: false,
	layoutType: 'menu-light',
	headerBackColor: 'header-blue',
	rtlLayout: false,
	navFixedLayout: true,
	headerFixedLayout: false,
	boxLayout: false,
	PAYMENT_LOADSCRIPT_URL: 'https://checkout.razorpay.com/v1/checkout.js',

	PAYMENT_KEY: 'rzp_live_5OfwNCb2GnqISW',
	//Live Key :- rzp_live_5OfwNCb2GnqISW
	//Test Mode Key :- rzp_test_UQvLCMy2pqehw8
	DOCUMENT_DOWNLOAD_URL: 'https://pdo.xifi-janwani.com:3902/', //Live
	// DOCUMENT_DOWNLOAD_URL: 'http://192.168.10.178:9001/', //local

	// Note: Live Urls
	BASE_URL: 'https://pdo.xifi-janwani.com:3902/api/v1/'

	// Note : Local Url
	// BASE_URL: 'https://pdostage.xifi-janwani.com//api/v1/'
	// BASE_URL: 'http://192.168.10.178:9001/api/v1/'
};
export default Config;

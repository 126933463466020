import * as React from 'react';
const DashboardDefault = React.lazy(() => import('./Demo/Dashboard/Default'));
const DashboardSales = React.lazy(() => import('./Demo/Dashboard/Sales'));
const DashboardCRM = React.lazy(() => import('./Demo/Dashboard/Crm'));
const DashboardAnalytics = React.lazy(() => import('./Demo/Dashboard/Analytics'));
const DashboardProject = React.lazy(() => import('./Demo/Dashboard/Project'));
const Users = React.lazy(() => import('./Demo/Users/UserList'));
const DistributorList = React.lazy(() => import('./Demo/Users/DistributorList'));
const Profile = React.lazy(() => import('./Demo/Users/UserProfileNew'));
const AdminProfile = React.lazy(() => import('./Demo/Users/AdminProfile'));
const Settings = React.lazy(() => import('./Views/Settings'));
const SupportForm = React.lazy(() => import('./Views/SupportForm'));
const AdminSubscription = React.lazy(() => import('./Views/AdminSubscription'));
const AdminPdobandwith = React.lazy(() => import('./Demo/Billing/AdminPdobandwith'));
const AdminpdoSignupCommission = React.lazy(() => import('./Demo/Billing/AdminpdoSignupCommission'));
const AdminSubscriptionCommission = React.lazy(() => import('./Demo/Billing/AdminSubscriptionCommission'));
const DeviceManagement = React.lazy(() => import('./Views/DeviceManagement'));
const AddEditDevice = React.lazy(() => import('./Views/AddEditDevice'));
const Subscription = React.lazy(() => import('./Views/Subscription'));
// const Accounting = React.lazy(() => import('./Demo/Accounting/accounting'));
const EvoucherPurchase = React.lazy(() => import('./Demo/Accounting/EvoucherPurchase'));
const EvoucherSales = React.lazy(() => import('./Demo/Accounting/EvoucherSales'));
const AdminevoucherSales = React.lazy(() => import('./Demo/Accounting/AdminevoucherSales'));
const AdminevoucherPurchase = React.lazy(() => import('./Demo/Accounting/AdminevoucherPurchase'));
const TotalRevenue = React.lazy(() => import('./Demo/Accounting/TotalRevenue'));
const BuyEvouchers = React.lazy(() => import('./Demo/Evouchers/buyEvouchers'));
const SellEvouchers = React.lazy(() => import('./Demo/Evouchers/sellEvoucher'));
const MyEvoucher = React.lazy(() => import('./Demo/Evouchers/myEvoucher'));
const SubscriptionBill = React.lazy(() => import('./Demo/Billing/SubscriptionBill'));
const BandwithIncom = React.lazy(() => import('./Demo/Billing/BandwithIncom'));
const Marketing = React.lazy(() => import('./Demo/Marketing/marketing'));

// const token = localStorage.getItem('auth');

const routes = [
	{ path: '/users', exact: true, name: 'Users', component: Users },
	{ path: '/distributorlist', exact: true, name: 'Users', component: DistributorList },
	{ path: '/profile', exact: true, name: 'Profile', component: Profile },
	{ path: '/adminprofile', exact: true, name: 'AdminProfile', component: AdminProfile },
	{ path: '/settings', exact: true, name: 'Settings', component: Settings },

	{ path: '/dashboard/default', exact: true, name: 'Analytics', component: DashboardDefault },
	{ path: '/dashboard', exact: true, name: 'Crypto', component: DashboardSales },
	{ path: '/dashboard/crm', exact: true, name: 'Crypto', component: DashboardCRM },
	{ path: '/dashboard/analytics', exact: true, name: 'Analytics', component: DashboardAnalytics },
	{ path: '/dashboard/project', exact: true, name: 'Crypto', component: DashboardProject },

	{ path: '/device-management', exact: true, name: 'Device Management', component: DeviceManagement },
	{ path: '/device-management/add', exact: true, name: 'Add Device', component: AddEditDevice },
	{ path: '/device-management/edit/:device_id', exact: true, name: 'Edit Device', component: AddEditDevice },

	{ path: '/subscription', exact: true, name: 'Subscription', component: Subscription },
	{ path: '/admin-subscription', exact: true, name: 'AdminSubscription', component: AdminSubscription },

	{ path: '/accounting/evoucherPurchase', exact: true, name: 'EvoucherPurchase', component: EvoucherPurchase },
	{ path: '/accounting/evouchersales', exact: true, name: 'EvoucherSales', component: EvoucherSales },
	{ path: '/accounting/totalRevenue', exact: true, name: 'TotalRevenue', component: TotalRevenue },
	{ path: '/accounting/adminevoucherSales', exact: true, name: 'AdminevoucherSales', component: AdminevoucherSales },
	{ path: '/accounting/adminevoucherPurchase', exact: true, name: 'AdminevoucherPurchase', component: AdminevoucherPurchase },

	{ path: '/evouchers/buyevoucher', exact: true, name: 'BuyEvouchers', component: BuyEvouchers },
	{ path: '/evouchers/myevoucher', exact: true, name: 'MyEvouchers', component: MyEvoucher },
	{ path: '/evouchers/sellevoucher', exact: true, name: 'SellEvouchers', component: SellEvouchers },

	{ path: '/billing/subscriptionbill', exact: true, name: 'SubscriptionBill', component: SubscriptionBill },
	{ path: '/billing/bandwithincome', exact: true, name: 'BandwithIncom', component: BandwithIncom },
	{ path: '/billing/pdobandwith', exact: true, name: 'AdminPdobandwith', component: AdminPdobandwith },
	{
		path: '/billing/ditributorbill/comission',
		exact: true,
		name: 'AdminpdoSignupCommission',
		component: AdminpdoSignupCommission
	},
	{
		path: '/billing/ditributorbill/renewal',
		exact: true,
		name: 'AdminSubscriptionCommission',
		component: AdminSubscriptionCommission
	},

	// { path: '/marketing', exact: true, name: 'Marketing', component: Marketing },

	{ path: '/support', exact: true, name: 'Support', component: SupportForm }
];

export default routes;
